.custom-modal-style {
  width: 100vh;
  height: 65vh;
}

.custom-modal-style-body {
  width: 100vh;
  min-height: 50vh;
  max-height: 50vh;
  overflow-y: scroll;
}

.custom_button {
  background-image: linear-gradient("to bottom left", #328eb6, #49a5da);
}

.btn-light {
  background-image: linear-gradient(to bottom left, #1d8cf8, #3358f4, #1d8cf8);
}

.btn-light:hover {
  background-image: linear-gradient(to bottom left, #1d8cf8, #3358f4, #1d8cf8);
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
